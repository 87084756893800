import React, { useState } from 'react'
import styles from "./plansPricing.module.scss"

const PlansPricing = () => {
    const [selectedPlan, setSelectedPlan] = useState("annullay")

    let pricingData = [
        {
            type: "Basic",
            value: "Build your event invitation list",
            price: "Free",
            annualPrice: "Free",
            promo: "",
            btnText: "Sign up Free",
            text: "Features",
            list: ["Master Directory Access", "Contact List Builder tools", "Search & Filter Tools", "Advanced Map Filtering","Family & Friends Suggestions ", "Automatic Contact Grouping", "Automatic Contact Updates", "Create Any Order Type", "Limited Phone/Address Display"],
            link: "https://portal.genvite.com/select-plan" 
        },
        {
            type: "Premium",
            value: "Limited GenTree family navigation",
            price: "$3.49",
            annualPrice: "$2.99",
            promo: "$6.99 Without Promo code",
            annualPromo: "$6.49 Without Promo code",
            btnText: "Start 7-Day Trial",
            text: "All Free Plan Features",
            list: ["Full Address & Phone Display", "GenTree Family Navigation", "Detailed Relationship Mapping", "Access to See Engaged Children", "Cell Phone & Email Access", "Wedding Year & Yeshiva Info", "Previous Address Access", "10 GenTree Lookups Per Day"],
            link: "https://portal.genvite.com/select-plan"
        },
        {
            type: "Premium+",
            value: "Unlimited GenTree family navigation",
            price: "$11.99",
            annualPrice: "$9.99",
            promo: "$15.49 Without Promo code",
            annualPromo: "$13.49 Without Promo code",
            btnText: "Sign Up to Premium+",
            text: "All Free and Premium Features",
            list: ["Unlimited GenTree Lookups", "Unlimited GenTree Navigation", "Priority Premium Support"],
            link: "https://portal.genvite.com/select-plan"
        }
    ]

  return (
    <div className={`content-wrpr my-5`}>
        <div className='row'>
            <div className='col-md-7 col-12 px-0'>
                <h2 className={`${styles.heading} fs36 fw600 ffp`} >Plans & Pricing</h2>
                <p className={`${styles.firstP} fw500 fs16 ffp`}>Sign up to enjoy an intuitive online invitation list building experience, and gain access to searches, relationship mapping, advanced filters, and exclusive historical records access. Discover more about your family and friends with Genvite. </p>
            </div>
            <div className='col-md-5 col-12 d-flex justify-content-end align-items-center px-0'>
                <div className={`rounded-5 d-flex align-self-center ${styles.btnGrp}`}>
                    <div onClick={()=>setSelectedPlan("annullay")} className={`fs12 fw500 ffp p-3 rounded-5 ${ selectedPlan === "annullay" && styles.active}`}><span>ANNUALLY</span></div>
                    <div onClick={()=>setSelectedPlan("monthly")} className={`fs12 fw500 ffp p-3 rounded-5 ${ selectedPlan === "monthly" && styles.active}`}><span>MONTHLY</span></div>
                </div>
            </div>
        </div>
        <div className='row p-0 gx-3 mt-md-0 mt-3 gy-3'>
            {
                pricingData.map((item, i)=>(
                    <div className='col-md-4 col-12 ' key={i}>
                        <PricingCard data={item} selectedPlan={selectedPlan} />
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default PlansPricing

const PricingCard = ({data, selectedPlan}) =>{
    return (
        <div className={`card w-100 h-100 border-0 shadow p-3 px-4 ${styles.card}`}>
            {data.type === "Premium+" && selectedPlan === "annullay" && <button className={`${styles.bestValue} fs10 fw800`}>BEST VALUE</button>}
            <h4 className={`${styles.cardH4} fs28 fw900 ffct`}>{data.type}</h4>
            <p className={`fs12 fw700 ffi ${styles.cardP}`}>{data.value}</p>
            <h5 className={`fs25 fw700 ffi ${styles.cardH5} mt-4 ${data.price === "Free" && 'mb-3'}`}>{selectedPlan === "annullay" ? data.annualPrice : data.price}<span className={`${styles.month} ffi fs16 fw400`}>{data.price !== "Free" && "/month" }</span></h5>
            <span className={`mt-0 fs12 fw300 ffi mb-3 ${styles.promo}`} >{selectedPlan === "annullay" ? data.annualPromo : data.promo}</span>
            <a className={`my-3 p-3 outline-none border-0 rounded-3 fs16 fw700 ffi ${styles.cardBtn}`} href={data.link} target='_self' >{data.btnText}</a>
            <h6 className={`fs15 fw700 ffp ${styles.cardH6}`} >{data.text}</h6>
            <ul>
                {data.list.map((item, i)=>(
                    <li key={i} className={`${styles.list} fs15 fw500 my-3 ffp`}>{item}</li>
                ))}
            </ul>
        </div>
    )
}